import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getTeamUuid } from '../../../../utils/cookies'
import { analysisChat, getAnalysis, getFileGptAnswer, getPublicAnalysis, updateAnalysisName, updateSpeakers } from '../../../../Requests/NewAnalysisRequests'
import { Bars2Icon, ChatBubbleLeftIcon, CheckIcon, CpuChipIcon, DocumentTextIcon, EyeIcon, ListBulletIcon, PencilIcon, SpeakerWaveIcon, TrashIcon } from '@heroicons/react/24/outline'
import { calculateQuestionCost, calculateQuestionCostByToken, formatDateTime } from '../../../../utils/calcs'
import OverallSentimentCard from '../../../../Components/NewAnalysisElements/OverallSentimentCard'
import { mapSentimentAndColors, sentimentTranslations } from '../../../../utils/mappings'
import { useTranslation } from 'react-i18next'
import OverallParticipationCard from '../../../../Components/NewAnalysisElements/OverallParticipationCard'
import { Card, Grid } from '@tremor/react'
import TextSectionCard from '../../../../Components/NewAnalysisElements/TextSectionCard'
import TimeBasedParticipationCard from '../../../../Components/NewAnalysisElements/TimeBasedParticipationCard'
import TimeBasedSentimentCard from '../../../../Components/NewAnalysisElements/TimeBasedSentimentCard'
import ViewTranscription from '../../../../Components/Meetings/ViewTranscription'
import MeetingOptionsMenu from '../../../../Components/Meetings/MeetingOptionsMenu'
import IdentifyUsersModal from '../../../../Components/Meetings/IdentifyUsersModal'
import TranscriptionModal from '../../../../Components/NewAnalysisElements/TranscriptionModal'
import DeleteAnalysisBlocksModal from '../../../../Components/NewAnalysisElements/DeleteMeetingBlocksModal'
import AnalysisPermissions from '../../../../Components/NewAnalysisElements/AnalysisPermissions'
import { useMyContext } from '../../../../Components/ContextProviders/CreditsContextProvider'
import NoCreditsModal from '../../../../Components/Billing/NoCreditsModal'
import Loader from '../../../../Components/Loader'
import ReactMarkdown from 'react-markdown';
import SectionHeader from '../../../../Components/GeneralComponents/SectionHeader'
import { CloudArrowUpIcon, XMarkIcon } from '@heroicons/react/20/solid'

function NewAnalysisHolder({ view_mode = "private" }) {
    const { analysis_uuid } = useParams()
    const [analysis, setAnalysis] = useState(null)
    const [speakerNames, setSpeakerNames] = useState(null)
    const [modal, setModal] = useState(null)
    const [transcription, setTranscription] = useState(null)
    const { t } = useTranslation();
    const [deletePopUp, setDeletePopUp] = useState(null)
    const navigate = useNavigate()
    const { credits, setCredits } = useMyContext();
    const [insufficientCredits, setInsufficientCredits] = useState(null)
    const [isAsking, setIsAsking] = useState(false)
    const [chatFragments, setChatFragments] = useState(null)
    const [isEditingAnalysisName, setIsEditingAnalysisName] = useState(false)
    const [question, setQuestion] = useState('');
    const textareaRef = useRef(null);

    useEffect(() => {
        if (analysis_uuid && !analysis) {
            updateAnalysis()
        }
    }, [analysis_uuid])

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [question]);

    const updateAnalysis = () => {
        if (view_mode === "public") {
            getPublicAnalysis(analysis_uuid).then((res) => {
                setAnalysis(res.data)
                setTranscriptionAndNames(res.data)
            }).catch(() => {
                navigate("/")
            })
        } else {
            let data = { "analysis_uuid": analysis_uuid, "team_uuid": getTeamUuid() }
            getAnalysis(data).then((res) => {
                setAnalysis(res.data)
                setTranscriptionAndNames(res.data)
                setChatFragments(res.data.fragments.filter(fragment => fragment.type === "chat"))
            }).catch(() => {
                navigate("/projects")
            })
        }
    }

    const fetchChatUpdates = async (uuid) => {
        try {
            const updatedChat = await getFileGptAnswer(uuid);
            return updatedChat;
        } catch (error) {
            throw error;
        }
    };

    const updateChatStatus = (uuid) => {
        fetchChatUpdates(uuid).then(updatedChat => {
            if (updatedChat.data?.status === "Processing") {
                setTimeout(() => updateChatStatus(uuid), 3000);
            } else if (updatedChat.data?.status === "Processed") {
                setChatFragments(currentChats => {
                    return currentChats.map(chat => {
                        setIsAsking(false)
                        if (chat.uuid === uuid) {
                            return updatedChat.data;
                        }
                        return chat;
                    });
                });
            } else {
                setChatFragments(currentChats => {
                    setIsAsking(false)
                    return currentChats.map(chat => {
                        if (chat.uuid === uuid) {
                            return { ...chat, result: "Failed to process the question", status: t("NewAnalysis.FailedToProcessQuestion") };
                        }
                        return chat;
                    });
                });
            }
        }).catch(error => {
            setChatFragments(currentChats => {
                setIsAsking(false)
                return currentChats.map(chat => {
                    if (chat.uuid === uuid) {
                        return { ...chat, result: "Failed to process the question", status: t("NewAnalysis.FailedToProcessQuestion") };
                    }
                    return chat;
                });
            })
        })
    };

    useEffect(() => {
        chatFragments?.forEach(chat => {
            if (chat.uuid && chat.status === "Processing") {
                updateChatStatus(chat.uuid);
            }
        });
    }, [chatFragments]);

    const setTranscriptionAndNames = (analysis) => {
        let names = null;
        let transcriptionFragment = analysis.fragments
            ?.find(fragment => fragment.type === "edited_transcription");
        if (analysis.speaker_names) {
            names = JSON.parse(analysis.speaker_names)
            setSpeakerNames(names)
        }
        if (transcriptionFragment) {
            const lines = (transcriptionFragment?.result.split('\n')).map((line) => {
                const parts = line.split(":");
                const timeAndSpeaker = parts[0].trim();
                const speakerMatch = timeAndSpeaker.match(/(Speaker\s+[A-Z])$/);
                const speakerKey = speakerMatch ? speakerMatch[0] : "";
                const time = speakerKey ? timeAndSpeaker.replace(speakerKey, "").trim() : timeAndSpeaker;
                let speakerName = speakerKey
                if (names) {
                    speakerName = speakerKey;
                }
                const final_line = {
                    staticPart: `${time} ${speakerName}:`,
                    editablePart: parts.slice(1).join(":").trim() || "",
                };
                if (final_line?.editablePart != "") {
                    return final_line
                } else {
                    return
                }
            });
            const filteredLines = lines.filter(line => line !== undefined);
            if (!filteredLines.every(element => element === undefined)) {
                setTranscription(filteredLines);
            } else {
                setTranscription(null)
            }
        }
    }

    const updateFragmentState = (fragment_uuid, newValue) => {
        if (analysis && analysis.fragments) {
            const updatedFragments = analysis.fragments.map(fragment => {
                if (fragment.uuid === fragment_uuid) {
                    return { ...fragment, result: newValue };
                }
                return fragment;
            });
            setAnalysis({ ...analysis, fragments: updatedFragments });
        }
    };

    const SpeakerColors = [
        "emerald-500", "yellow-500", "purple-500", "red-500", "lime-500",
        "zinc-500", "gray-500", "indigo-500", "orange-500", "black",
        "teal-500", "cyan-500",
    ];

    const getSpeakerColorRaw = (speaker) => {
        const letter = (speaker.split(' ')[1]).split('-')[0];
        if (!letter) return SpeakerColors[0];
        const index = letter.charCodeAt(0) - 'A'.charCodeAt(0);
        return SpeakerColors[index % SpeakerColors.length];
    };

    const getSpeakerColor = (letter) => {
        const index = letter.charCodeAt(0) - 'A'.charCodeAt(0);
        return SpeakerColors[index % SpeakerColors.length];
    };

    const getLegendData = (sentimentData) => {
        const parsedData = JSON.parse(sentimentData);
        const letters = [];
        Object.keys(parsedData).forEach(speaker => {
            const parts = speaker?.includes('Speaker ') ? speaker.split(' ')[1] : speaker;
            const letter = parts.split('-')[0];
            if (!letters?.includes(letter)) {
                letters.push(letter);
            }
        });
        return letters.map((letter) => {
            return {
                label: `Speaker ${letter}`,
                color: getSpeakerColor(letter)
            };
        });
    };

    const handleSaveNames = (names) => {
        let data = { "speaker_names": JSON.stringify(names), "analysis_uuid": analysis.uuid }
        updateSpeakers(data).then((res) => {
            window.location.reload(false)
        }).catch((e) => {
            // Handle error
        })
    }

    const handleEditNames = () => {
        setModal(<IdentifyUsersModal handleClose={() => { setModal(null) }} speakerNames={speakerNames} handleSaveNames={handleSaveNames} />)
    }

    const handleDeleteData = () => {
        setModal(<DeleteAnalysisBlocksModal handleClose={() => { setModal(null) }} analysis={analysis} setDeletePopUp={setDeletePopUp} updateAnalysis={updateAnalysis} />)
    }

    const handleVisibilitySettings = () => {
        setModal(<AnalysisPermissions handleClose={() => { setModal(null) }} analysis={analysis} setDeletePopUp={setDeletePopUp} updateAnalysis={updateAnalysis} />)
    }

    const handleQuestionChange = (e) => {
        setQuestion(e.target.value);
    };

    const handleQuestion = (e) => {
        e.preventDefault()
        setIsAsking(true)
        let data = {
            "analysis_uuid": analysis_uuid,
            "prompt": question,
            "team_uuid": getTeamUuid()
        }
        analysisChat(data).then((res) => {
            let newCredits = credits - (analysis.tokens == null ? calculateQuestionCost(parseFloat(analysis?.duration) || 0) : calculateQuestionCostByToken(analysis?.tokens))
            setCredits(newCredits)
            setIsAsking(false)
            updateAnalysis()
            setQuestion(''); // Clear the textarea after sending
        }).catch((e) => {
            if (JSON.parse(e.request.response)["message"] == "Insufficient credits") {
                setInsufficientCredits(<NoCreditsModal
                    title={t('CreditsModal.Title')}
                    body={t('CreditsModal.Body')}
                    continue={t('CreditsModal.Continue')}
                    navLink="/pricing/credits"
                    handleClose={() => { setInsufficientCredits(null) }} />)
            }
            setIsAsking(false)
        })
    }

    const scrollContainerRef = useRef(null);

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
        }
    }, [chatFragments]);

    const handleAnalysisName = (event) => {
        event.preventDefault()
        updateAnalysisName({ uuid: analysis_uuid, 'name': event.target.analysisName.value }).then(() => {
            setIsEditingAnalysisName(false)
            analysis.name = event.target.analysisName.value
        }).catch(() => { })
    }

    return (
        <div className={view_mode == "public" ? ' py-2 md:py-12 w-full flex flex-col gap-4 items-center px-4' : 'md:pl-64 py-2 md:py-12 w-full flex flex-col gap-4 items-center px-4'}>
            <div className='flex flex-col w-full justify-center items-center gap-8 md:px-4'>
                {analysis?.project && <div className='bg-white max-w-7xl rounded-2xl w-full h-full shadow-xl px-2'>
                    <SectionHeader
                        title={analysis?.project?.name}
                        subtitle={analysis?.project?.description}
                        breadcrubs={[{ name: t('Projects.Title'), href: "/projects" }, { name: analysis.project?.name, href: "/project/" + analysis.project?.uuid }]}
                    />
                </div>}

                <div className='flex flex-col w-full justify-center items-center '>

                    <div className='bg-white max-w-7xl rounded-2xl w-full h-full shadow-xl mx-8'>
                        {analysis &&
                            <div className='shadow-2xl px-4 py-4 w-full bg-white rounded-2xl'>
                                <div className='flex justify-between flex-col md:flex-row'>
                                    <div className='flex items-center justify-start mb-4 ml-1'>
                                        {analysis.file_type == "audio" && <SpeakerWaveIcon className='w-10 h-10' />}
                                        {analysis.file_type == "file" && <DocumentTextIcon className='w-10 h-10' />}
                                        <div className=' pl-4'>
                                            {isEditingAnalysisName ?
                                                <form className='text-base font-semibold  text-gray-900 flex gap-2' onSubmit={handleAnalysisName}>
                                                    <input
                                                        name='analysisName'
                                                        id='analysisName'
                                                        type='text'
                                                        className='text-sm py-2 my-2'
                                                        defaultValue={analysis.name}
                                                    ></input>
                                                    <div className='flex items-center gap-4'>
                                                        <button className='border border-black bg-white text-black p-2 rounded-md flex gap-2 items-center shadow-md text-sm'
                                                            onClick={() => setIsEditingAnalysisName(false)}
                                                            type='button'
                                                        >
                                                            <XMarkIcon className='w-4 h-4' /> {t('MeetingSection.CancelLabel')}
                                                        </button>
                                                        <button className='border border-black bg-black text-white p-2 rounded-md flex gap-2 items-center shadow-md text-sm'
                                                            type='submit'
                                                        >
                                                            <CloudArrowUpIcon className='w-4 h-4' /> {t('MeetingSection.SaveLabel')}
                                                        </button>
                                                    </div>
                                                </form>
                                                : <div className='text-base font-semibold  text-gray-900 flex gap-2'>
                                                    <div>{analysis.name}</div>
                                                    <button>
                                                        <PencilIcon className='text-gray-400 text-sm w-3 h-3' onClick={() => { setIsEditingAnalysisName(true) }}></PencilIcon>
                                                    </button>
                                                </div>
                                            }
                                            <div className='text-sm text-gray-500'>{formatDateTime(analysis.created_at)}</div>
                                        </div>
                                    </div>
                                    <div className='flex flex-col md:flex-row gap-4 justify-between items-center print:hidden mb-4 md:mb-0 '>

                                        {/*speakerNames && <button className='text-sm border-2 border-black  rounded-xl h-fit px-4 py-2 shadow-lg'
                        onClick={() => {
                            setModal(<IdentifyUsersModal handleClose={() => { setModal(null) }} speakerNames={speakerNames} handleSaveNames={handleSaveNames} />)
                        }}
                    >
                        <div className='flex justify-between gap-2 items-center'>
                            <UserGroupIcon className=' w-4 h-4' />
                            {t('TranscriptionModal.UpdateNamesTitle')}
                        </div>
                    </button>
                    */}
                                        {(!(view_mode == "public") && transcription) &&
                                            (analysis.fragments
                                                ?.filter(fragment => {
                                                    // Include only fragments with the type 'transcription'
                                                    return fragment.type === "edited_transcription";
                                                })?.map((fragment, index) => {
                                                    return (<button className='text-sm border-2 border-black bg-black text-white rounded-xl h-fit px-4 py-2 shadow-lg w-full'
                                                        onClick={() => {
                                                            setModal(<TranscriptionModal handleClose={() => { setModal(null) }} analysis={analysis} fragment={fragment} transcriptions={transcription} updateFragmentState={updateFragmentState} view_mode={view_mode} setTranscription={setTranscription} speakerNames={speakerNames} />)
                                                        }}
                                                    >
                                                        <div className='flex  gap-2 items-center'>
                                                            <EyeIcon className=' w-4 h-4' />
                                                            {t('ExpandedMeedingCard.ViewTranscriptionButton')}
                                                        </div>
                                                    </button>)
                                                }))
                                        }

                                        {!(view_mode == "public") && <div className="py-1 w-full">
                                            <button
                                                type='button'
                                                onClick={() => handleDeleteData()}
                                                href="#"
                                                className='text-sm border-2 border-red-800 bg-red-800 text-white rounded-xl h-fit px-4 py-2 shadow-lg w-full'
                                            >
                                                <div className='flex  gap-2 items-center whitespace-nowrap'>
                                                    <TrashIcon className="mr-3 h-4 w-4 text-white-400 group-hover:text-gray-500" aria-hidden="true" />
                                                    {t('ExpandedMeetingMenu.DeleteItemsLabel')}
                                                </div>
                                            </button>
                                        </div>}

                                        {
                                            !(view_mode == "public") && <MeetingOptionsMenu meeting={analysis} handleEditNames={handleEditNames} handleDeleteData={handleDeleteData} handleVisibilitySettings={handleVisibilitySettings} />
                                        }


                                        {/*<button className='text-sm bg-black text-white rounded-xl h-fit px-4 py-2 border-2 border-black shadow-lg'

                >{t('ExpandedMeedingCard.EditButton')}</button>*/}
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-2 pb-8'>
                                    {analysis.fragments?.map((fragment, index) => {
                                        // Determine if the fragment is either "overall_sentiment" or "overall_participation"
                                        const isTargetFragment = ["overall_sentiment", "overall_participation"]?.includes(fragment.type);

                                        return (
                                            isTargetFragment && (
                                                <div key={index} className='md:col-span-1 col-span-2'>
                                                    {fragment.type === "overall_sentiment" && (
                                                        <OverallSentimentCard
                                                            fragment={fragment}
                                                            t={t}
                                                            sentimentTranslations={sentimentTranslations}
                                                            mapSentimentAndColors={mapSentimentAndColors}
                                                        />
                                                    )}
                                                    {fragment.type === "overall_participation" && (
                                                        <OverallParticipationCard
                                                            duration={analysis?.duration || 0}
                                                            fragment={fragment}
                                                            t={t}
                                                            speakerNames={speakerNames}
                                                        />
                                                    )}
                                                </div>
                                            )
                                        );
                                    })}

                                    {/* Render non-target fragments in full width */}
                                    {analysis.fragments
                                        ?.filter(fragment => {
                                            // Include only fragments that match specific conditions
                                            return ["text_summary", "bullets_summary", "tasks", "time_based_participation", "time_based_sentiment"].includes(fragment.type);
                                        })
                                        .map((fragment, index) => {
                                            const isTargetFragment = ["overall_sentiment", "overall_participation"]?.includes(fragment.type);

                                            if (!isTargetFragment) {
                                                return (
                                                    <div key={`other-${index}`} className='col-span-2'>
                                                        {fragment.type === "text_summary" && (
                                                            <TextSectionCard
                                                                icon={Bars2Icon}
                                                                title={t('ExpandedMeedingCard.SummaryLabel')}
                                                                content={fragment.result}
                                                                meeting_uuid={analysis.uuid}
                                                                lines={20}
                                                                content_name="text_summary"
                                                                fragment={fragment}
                                                                updateFragmentState={updateFragmentState}
                                                                speakerNames={speakerNames}
                                                                view_mode={view_mode}
                                                            />
                                                        )}
                                                        {fragment.type === "bullets_summary" && (
                                                            <TextSectionCard
                                                                icon={ListBulletIcon}
                                                                title={t('ExpandedMeedingCard.SummaryByPointsLabel')}
                                                                content={fragment.result}
                                                                meeting_uuid={analysis.uuid}
                                                                lines={20}
                                                                content_name="bullets_summary"
                                                                fragment={fragment}
                                                                updateFragmentState={updateFragmentState}
                                                                speakerNames={speakerNames}
                                                                view_mode={view_mode}
                                                            />
                                                        )}
                                                        {fragment.type === "tasks" && (
                                                            <TextSectionCard
                                                                icon={CheckIcon}
                                                                title={t('ExpandedMeedingCard.TasksLavel')}
                                                                content={fragment.result}
                                                                meeting_uuid={analysis.uuid}
                                                                lines={20}
                                                                content_name="tasks"
                                                                fragment={fragment}
                                                                updateFragmentState={updateFragmentState}
                                                                speakerNames={speakerNames}
                                                                view_mode={view_mode}
                                                            />
                                                        )}
                                                        {fragment.type === "time_based_participation" &&
                                                            <TimeBasedParticipationCard
                                                                duration={analysis.duration}
                                                                fragment={fragment}
                                                                t={t}
                                                                getSpeakerColorRaw={getSpeakerColorRaw}
                                                                getLegendData={getLegendData}
                                                                speakerNames={speakerNames} />
                                                        }
                                                        {fragment.type === "time_based_sentiment" &&
                                                            <TimeBasedSentimentCard
                                                                duration={analysis.duration}
                                                                fragment={fragment}
                                                                t={t}
                                                                sentimentTranslations={sentimentTranslations}
                                                                mapSentimentAndColors={mapSentimentAndColors} />
                                                        }
                                                    </div>
                                                );
                                            }
                                            return null; // Explicitly return null for unmatched types to avoid rendering empty divs
                                        })}

                                    {view_mode != "public" &&
                                        <div className='w-full col-span-2'>

                                            <div className='text-sm my-4 print:hidden '>
                                                <Card>
                                                    <div className='flex gap-2 items-center text-lg mb-4'>
                                                        <ChatBubbleLeftIcon className='border border-black text-black bg-white rounded-md w-6 h-6 px-0.5 py-0.5'></ChatBubbleLeftIcon>
                                                        {t('ExpandedMeedingCard.ChatLabel')}
                                                    </div>
                                                    <div ref={scrollContainerRef} className='flex flex-col grow bg-gray-100 p-4  overflow-y-scroll my-4 rounded-lg max-h-96 md:text-base'>
                                                        {chatFragments && chatFragments.map((fragment) => {
                                                            return (
                                                                <div className='py-2'>
                                                                    <div><span className='font-bold text-lg'>{t('Analysis.QuestionLabel')} </span>{fragment.input}</div>
                                                                    <div><span className='font-bold text-lg'>{t('Analysis.AnsweLabel')} </span>
                                                                        {fragment.result && fragment.result == "error" ? <span className='text-red-500'>{t('NewAnalysis.FailedToProcessQuestion')}</span> : <ReactMarkdown className={"py-2" + (fragment.result ? "" : " animate-pulse")}>{(fragment.result) || t('Analysis.GeneratingResponse')}</ReactMarkdown>}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <form onSubmit={handleQuestion} className='flex flex-col md:flex-row w-full gap-2 items-end'>
                                                        <textarea
                                                            ref={textareaRef}
                                                            value={question}
                                                            onChange={handleQuestionChange}
                                                            name="question"
                                                            id="question"
                                                            className="block w-full text-start rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                                                            placeholder={t('ExpandedMeedingCard.ChatPlaceholder')}
                                                            style={{ minHeight: '2.5rem', resize: 'none' }}
                                                            rows={1}
                                                        />
                                                        <button className='bg-black text-white rounded-lg px-4 py-2 w-full md:w-auto h-fit'>
                                                            {isAsking ? <Loader /> :
                                                                <div className='flex justify-between items-center gap-3'>
                                                                    <div>Ask</div>
                                                                    <div className='flex items-center gap-1'>
                                                                        <div className='font-bold'>
                                                                            {(analysis.file_type == "audio" && analysis.tokens == null) ? calculateQuestionCost(parseFloat(analysis?.duration)?.toFixed(2) || 0) : calculateQuestionCostByToken(analysis?.tokens)?.toFixed(2)}
                                                                        </div>
                                                                        <CpuChipIcon className='w-5 h-5' />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </button>
                                                    </form>
                                                </Card>
                                            </div>
                                            <div className='prints hidden print:block'>
                                                <Card >
                                                    <div className='flex gap-2 items-center text-lg mb-4'>
                                                        <ChatBubbleLeftIcon className='border border-black text-black bg-white rounded-md w-6 h-6 px-0.5 py-0.5' />
                                                        {t('TranscriptionModal.TranscriptionLabel')}
                                                    </div>
                                                    <div >
                                                        {transcription?.map((line, index) => {
                                                            if (line?.staticPart?.length > 1) {
                                                                return <div className="text-base my-2">
                                                                    <div className="text-base mt-0">{line.staticPart.replace("-", "s-").replace("s]")} {line.editablePart}</div>
                                                                </div>
                                                            }
                                                        })}

                                                    </div>
                                                </Card>
                                            </div>

                                        </div>
                                    }
                                </div>


                            </div>

                        }

                    </div>

                </div>

                {modal}
                {deletePopUp}
                {insufficientCredits}
            </div>
        </div>
    )
}

export default NewAnalysisHolder
